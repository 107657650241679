const BlinkMacSystemFontBold =
    'https://sokafans-static.s3.eu-central-1.amazonaws.com/fonts/BlinkMacSystemFont-Bold.woff';
const BlinkMacSystemFontLight =
    'https://sokafans-static.s3.eu-central-1.amazonaws.com/fonts/BlinkMacSystemFont-Light.woff';
const BlinkMacSystemFontMedium =
    'https://sokafans-static.s3.eu-central-1.amazonaws.com/fonts/BlinkMacSystemFont-Medium.woff';
const BlinkMacSystemFontRegular =
    'https://sokafans-static.s3.eu-central-1.amazonaws.com/fonts/BlinkMacSystemFont-Regular.woff';

const GTWalsheimProRegular = 'https://sokafans-static.s3.eu-central-1.amazonaws.com/fonts/GTWalsheimPro-Regular.ttf';
const GTWalsheimProBold = 'https://sokafans-static.s3.eu-central-1.amazonaws.com/fonts/GTWalsheimPro-Bold.ttf';
const GTWalsheimProMedium = 'https://sokafans-static.s3.eu-central-1.amazonaws.com/fonts/GTWalsheimPro-Medium.ttf';

export default function CssBaseline(theme) {
    return {
        MuiCssBaseline: {
            styleOverrides: `
                        @font-face {
                            font-family: 'BlinkMacSystemFont';
                            src: url('${BlinkMacSystemFontBold}') format('woff');
                            font-weight: bold;
                            font-style: normal;
                            font-display: swap;
                        }
                        @font-face {
                            font-family: 'BlinkMacSystemFont';
                            src: url('${BlinkMacSystemFontLight}') format('woff');
                            font-weight: 300;
                            font-style: normal;
                            font-display: swap;
                        }
                        @font-face {
                            font-family: 'BlinkMacSystemFont';
                            src: url('${BlinkMacSystemFontMedium}') format('woff');
                            font-weight: 500;
                            font-style: normal;
                            font-display: swap;
                        }
                        @font-face {
                            font-family: 'BlinkMacSystemFont';
                            src: url('${BlinkMacSystemFontRegular}') format('woff');
                            font-weight: normal;
                            font-style: normal;
                            font-display: swap;
                        }
                        
                        @font-face {
                          font-family: 'GT Walsheim Pro';
                          src: url('GTWalsheimPro-Regular.eot');
                          src: local('GT Walsheim Pro Regular'), local('GTWalsheimPro-Regular'),
                              url('${GTWalsheimProRegular}') format('truetype');
                          font-weight: normal;
                          font-style: normal;
                          }
                          
                          @font-face {
                              font-family: 'GT Walsheim Pro';
                              src: url('GTWalsheimPro-Bold.eot');
                              src: local('GT Walsheim Pro Bold'), local('GTWalsheimPro-Bold'),
                                  url('${GTWalsheimProBold}') format('truetype');
                              font-weight: bold;
                              font-style: normal;
                          }
                          
                          @font-face {
                            font-family: 'GT Walsheim Pro';
                            src: url('GTWalsheimPro-Medium.eot');
                            src: local('GT Walsheim Pro Medium'), local('GTWalsheimPro-Medium'),
                                url('${GTWalsheimProMedium}') format('truetype');
                            font-weight: 500;
                            font-style: normal;
                          }
                        
                    `
        }
    };
}
