import { Outlet } from 'react-router-dom';
import { Box, Paper, Typography } from '@mui/material';

import Title from '../components/Title';
import TopAppBar from '../components/app-bar/TopAppBar';
import Footer from './Footer';
import { DARK_PURPLE } from 'themes/colors';

export const BannerSectionSmall = () => {
    return (
        // <Container sx={{ my: 1 }}>
            <Paper
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 1,
                    background: DARK_PURPLE,
                    color: 'white',
                    textAlign: 'center',
                    gap: 1,
                    m: 0,
                    borderRadius: 0,
                }}
                variant="outlined"
            >
                <Typography variant="h4" sx={{ textTransform: "capitalize" }}>Home of Kenyan Leaked Videos</Typography>
            </Paper>
        // </Container>
    );
};

const MainLayout = () => {
    return (
        <>
            <Title title="Home" />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    padding: 0,
                    margin: 0,
                    minHeight: '100vh',
                    // position: "relative",
                    w: '100%',
                    gap: 0,
                }}
            >
                {/* <BannerSectionSmall /> */}
                <TopAppBar />
                <Outlet />
                <Footer />
            </Box>
        </>
    );
};

export default MainLayout;
