import { requestCallNoAccess, handleError } from "../../../../api/request";

export const getAccessToken = (payload, callback) => {
    const url = "/api/core/account/email-login";
    requestCallNoAccess(url, 'POST', payload).then((response) => {
        console.log(response.data);
        callback(false, response.data);
    }).catch((error) => {
        if (error && error.response) {
            // console.log(error.response);
            if (error.response.data) {
                callback(error.response.data);
            } else {
                callback({detail: `Failed to connect - ${error.response.status}. Try again`});
            }
        } else {
            callback({detail: "Failed to connected"});
        };
    });
};

export const getAccessTokenRegister = (payload, callback) => {
    const url = "/api/core/account/register";
    requestCallNoAccess(url, 'POST', payload).then((response) => {
        callback(false, response.data);
    }).catch((error) => {
        if (error && error.response) {
            // console.log(error.response);
            if (error.response.data) {
                callback(error.response.data);
            } else {
                callback({detail: `Failed to connect - ${error.response.status}. Try again`});
            }
        } else {
            callback({detail: "Failed to connected"});
        };
    })
};