import { lazy } from "react";

import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";

const HomePage = Loadable(lazy(() => import("../pages/home/Home")));
const PaymentPage = Loadable(lazy(() => import("../pages/payment/Payment")));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <HomePage />
    },
    {
      path: "pay",
      element: <PaymentPage />
    },
  ]
};

export default MainRoutes;