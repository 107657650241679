import React from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import {
    Checkbox,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    OutlinedInput,
    Stack,
    Typography,
    FormControl,
    Box
} from '@mui/material';

import * as Yup from 'yup';
import 'yup-phone';
import { Formik } from 'formik';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';

import { useCookies } from 'react-cookie';

import { getAccessToken, getAccessTokenRegister } from './repository/login';
import AnimateButton from '../../@extended/AnimateButton';

import { strengthIndicator, strengthColor } from './password-strength';

const Auth = ({ handleLoadUser }) => {
    const [_, setCookie] = useCookies(['access']);
    const navigate = useNavigate();

    // const [checked, setChecked] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => event.preventDefault();

    const [level, setLevel] = React.useState();
    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setLevel(strengthColor(temp));
    };

    const formInitialValue = {
        username: '',
        email: '',
        password: '',
        passwordConfirm: '',
        phone: '',

        submit: null
    };

    const formValidationSchema = Yup.object().shape({
        username: Yup.string()
            .max(25)
            .min(4)
            .required('username is required')
            .test(
                'username-lowercase',
                'Username should be lowercase and have no special characters',
                function (value) {
                    return /(?=[a-zA-Z0-9._]{4,25}$)(?!.*[_.]{2})[^_.].*[^_.]/.test(value);
                }
            )
            .required('Username is required'),
        email: Yup.string().email('Must be a valid email').max(255).required('email is required'),
        password: Yup.string().max(255).min(8).required('Password is required'),
        passwordConfirm: Yup.string()
            .required('Password confirmation is required')
            .test('passwords-match', 'Passwords must match', function (value) {
                return this.parent.password === value;
            }),
            phone: Yup.string().phone('KE').required()
    });

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const handleOnSubmit = (values, { setErrors, setStatus, setSubmitting }) => {
        setSubmitting(true);
        // await sleep(1500);

        const data = {
            username: values.username,
            email: values.email,
            password: values.password,
            first_name: '',
            last_name: '',
            phone: values.phone,
        };

        getAccessTokenRegister(data, (error, data) => {
            if (error) {
                setErrors({ ...error, submit: error['detail'] });
                setStatus({ success: false });
            } else {
                const access = data['access'];
                if (access) {
                    const date = new Date();
                    date.setDate(new Date().getDate() + 365);
                    setCookie('access', access, {
                        path: '/',
                        expires: date,
                        maxAge: 3600 * 24 * 364
                    });
                    setStatus({ success: true });
                    // navigate("/");
                    window.location.reload();
                    // handleLoadUser();
                } else {
                    setStatus({ success: false });
                    setErrors({ submit: 'Failed to login. Try again' });
                }
            }
            setSubmitting(false);
        });
    };

    return (
        <Formik initialValues={formInitialValue} validationSchema={formValidationSchema} onSubmit={handleOnSubmit}>
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit} autoComplete="off">
                    <Grid container spacing={3} sx={{ mt: "1px" }}>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="username-signup">Username</InputLabel>
                                <OutlinedInput
                                    id="username-signup"
                                    type="username"
                                    value={values.username}
                                    name="username"
                                    autoComplete="off"
                                    size="small"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Enter username"
                                    fullWidth
                                    error={Boolean(touched.username && errors.username)}
                                />
                                {touched.username && errors.username && (
                                    <FormHelperText error id="helper-text-username-signup">
                                        {errors.username}
                                    </FormHelperText>
                                )}
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="email-signup">Email Address</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    error={Boolean(touched.email && errors.email)}
                                    id="email-signup"
                                    type="email"
                                    autoComplete="off"
                                    size="small"
                                    value={values.email}
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="demo@company.com"
                                    inputProps={{}}
                                />
                                {touched.email && errors.email && (
                                    <FormHelperText error id="helper-text-email-signup">
                                        {errors.email}
                                    </FormHelperText>
                                )}
                            </Stack>
                        </Grid>

                        <Grid item xs={12}>
                            <Stack spacing={1}>
                            <InputLabel htmlFor="phone">Phone Number</InputLabel>
                                    <OutlinedInput
                                        id="phone"
                                        type="phone"
                                        value={values.phone}
                                        name="phone"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Enter your phone number"
                                        fullWidth
                                        autoComplete="off"
                                        // size="small"
                                        startAdornment={<InputAdornment position="start">+254</InputAdornment>}
                                        error={Boolean(touched.phone && errors.phone)}
                                    />
                                    {touched.phone && errors.phone && (
                                        <FormHelperText id="phone-helper-text" error>
                                            {errors.phone}
                                        </FormHelperText>
                                    )}
                            </Stack>
                        </Grid>

                        <Grid item xs={12}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="password-signup">Password</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    error={Boolean(touched.password && errors.password)}
                                    id="password-signup"
                                    type={showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    name="password"
                                    autoComplete="off"
                                    size="small"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changePassword(e.target.value);
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                size="large"
                                            >
                                                {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    placeholder="******"
                                    inputProps={{}}
                                />
                                {touched.password && errors.password && (
                                    <FormHelperText error id="helper-text-password-signup">
                                        {errors.password}
                                    </FormHelperText>
                                )}
                            </Stack>
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <Box
                                            sx={{
                                                bgcolor: level?.color,
                                                width: 85,
                                                height: 8,
                                                borderRadius: '7px'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" fontSize="0.75rem">
                                            {level?.label}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="password-confirmation-signup">Confirm Password</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
                                    id="password-confirmation-signup"
                                    type={showPassword ? 'text' : 'password'}
                                    value={values.passwordConfirm}
                                    name="passwordConfirm"
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    size="small"
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                size="large"
                                            >
                                                {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    placeholder="******"
                                    inputProps={{}}
                                />
                                {touched.passwordConfirm && errors.passwordConfirm && (
                                    <FormHelperText error id="helper-text-password-confirmation-signup">
                                        {errors.passwordConfirm}
                                    </FormHelperText>
                                )}
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                By signing up, you agree to out &nbsp;
                                <Link variant="subtitle2" component={RouterLink} to="#">
                                    Terms of Service
                                </Link>
                                &nbsp; and &nbsp;
                                <Link variant="subtitle2" component={RouterLink} to="#">
                                    Privacy Policy
                                </Link>
                            </Typography>
                        </Grid>
                        {errors.submit && (
                            <Grid item xs={12}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <AnimateButton>
                                <LoadingButton
                                    loading={isSubmitting}
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Create Account
                                </LoadingButton>
                            </AnimateButton>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    );
};

const FormRow = ({ children }) => (
    <Grid item xs={12}>
        <Stack spacing={1}>{children}</Stack>
    </Grid>
);

export default Auth;
