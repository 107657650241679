import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';

import AuthLogin from '../form/AuthLogin';
import AuthWrapperModal from '../AuthWrapperModal';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PropTypes from 'prop-types';

const Login = ({ handleOpenRegister, handleClose, handleLoadUser }) => (
    <AuthWrapperModal>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                    sx={{ mb: { xs: -0.5, sm: 0.5 } }}
                >
                    <Typography variant="h3">Login</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography
                            component={Button}
                            onClick={handleOpenRegister}
                            variant="body1"
                            sx={{ textDecoration: 'none' }}
                            color="primary"
                        >
                            Don&apos;t have an account?
                        </Typography>

                        <IconButton onClick={handleClose} aria-label="delete" color="primary">
                            <CloseRoundedIcon />
                        </IconButton>
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <AuthLogin handleLoadUser={handleLoadUser} />
            </Grid>
        </Grid>
    </AuthWrapperModal>
);

Login.props = {
    handleClose: PropTypes.func,
    handleOpenRegister: PropTypes.func
};

export default Login;
