import axios from './axios';

export const requestCall = (
    url,
    accessToken,
    method = 'GET',
    payload = {},
    extraHeaders = {},
    onUploadProgress = () => null
) => {
    const config = {
        url: url,
        method: method,
        headers: {
            Authorization: `Bearer ${accessToken}`,
            ...extraHeaders
        },
        data: payload,
        onUploadProgress: onUploadProgress
    };
    return axios.request(config);
};

export const requestCallNoAccess = (url, method = 'GET', payload = {}) => {
    const config = {
        url: url,
        method: method,
        data: payload
    };
    // console.log(config);
    return axios.request(config);
};

export const handleError = (error, callback) => {
    if (error && error.response) {
        // console.log(error.response);
        if (error.response.status === 400) {
            const message = error.response.data['detail'];
            callback(message);
        } else {
            callback(`Failed to connect - ${error.response.status}`);
        }
    } else {
        callback('Failed to connected');
    }
};

export const handleFormError = (error, callback) => {
    if (error && error.response) {
        // console.log(error.response);
        if (error.response.data) {
            callback(error.response.data);
        } else {
            callback({ detail: `Failed to connect - ${error.response.status}. Try again` });
        }
    } else {
        
        callback({ detail: `Failed to connected - ${error}` });
    }
};
