export const DARK_PURPLE = "#231b30";
export const DARKER_PURPLE = "#110c1a";
export const LIGHTER_PURPLE = "#30293b";

export const BUTTON_COLOR = "#9447ff";
export const DARK_BUTTON_COLOR = "#292531";

export const OUTLINED_INPUT_BACKGROUND = "#efeef1";


export const BACKGROUND_WHITE = "#f7f7f8";
export const SURFACE_WHITE = "#ffffff";
export const SURFACE_WHITE_2 = "#edecef";
export const TWICTH_PURPLE = "#5f0098";