import React from "react";

import {
  Box,
  Typography,
  Link,
  Paper,
  Divider,
  Container,
  Modal,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ReactMarkdown from "react-markdown";

import Logo from "../../assets/Logo.webp";

import PrivacyFile from "assets/docs/privacy.md";
import AboutFile from "assets/docs/about.md";
import RulesFile from "assets/docs/rules.md";
import TermsFile from "assets/docs/terms.md";
import AuthWrapperModal from "components/auth/AuthWrapperModal";

const MyFileViewer = ({ text }) => (
  <ReactMarkdown
    className="markdown"
    style={{
      fontWeight: "small",
      fontSize: 10,
    }}
  >
    {text}
  </ReactMarkdown>
);

const Footer = () => {
  const [openFile, setOpenFile] = React.useState(false);
  const handleClose = () => setOpenFile(false);

  const [title, setTitle] = React.useState("Privacy Policy");
  const [text, setText] = React.useState("");

  const [pFile, setPFile] = React.useState(null);
  const [tFile, setTFile] = React.useState(null);
  const [aFile, setAFile] = React.useState(null);
  const [rFile, setRFile] = React.useState(null);

  const loadFile = (filePath, callback) => {
    fetch(filePath)
      .then((response) => response.text())
      .then((data) => callback(data))
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    loadFile(PrivacyFile, setPFile);
    loadFile(TermsFile, setTFile);
    loadFile(AboutFile, setAFile);
    loadFile(RulesFile, setRFile);
  }, []);

  const handlePrivacyPolicy = () => {
    setText(pFile);
    setTitle("Privacy Policy");
    setOpenFile(true);
  };

  const handleTermsOfService = () => {
    setText(tFile);
    setTitle("Terms of Service");
    setOpenFile(true);
  };

  const handleAboutUs = () => {
    setText(aFile);
    setTitle("About Us");
    setOpenFile(true);
  };

  const handleRulesFile = () => {
    setText(rFile);
    setTitle("Content Rules");
    setOpenFile(true);
  };

  const legalItems = [
    { name: "Privacy Policy", onClick: handlePrivacyPolicy },
    { name: "Terms of Service", onClick: handleTermsOfService },
    { name: "Content Rules", onClick: handleRulesFile },
    // { name: 'About Us', onClick: handleAboutUs }
  ];

  const contactItems = [
    { name: "About Us", href: null, onClick: handleAboutUs },
    {
      name: "support@kenyafit.com",
      href: "email:support@kenyafit.com",
      onClick: null,
    },
    { name: "+254-796-191659", href: "tel:+254796191659", onClick: null },
  ];

  return (
    <Box
      sx={{
        flex: "0 0 50px",
        backgroundColor: "#ededed",
        marginTop: "auto",
        // color: 'white'
      }}
    >
      <Modal open={openFile} onClose={handleClose}>
        <AuthWrapperModal>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
                sx={{ mb: { xs: -0.5, sm: 0.5 } }}
              >
                <Typography variant="h3">{title}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    onClick={handleClose}
                    aria-label="delete"
                    color="primary"
                  >
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
              </Stack>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ maxHeight: { xs: "100vh", sm: "70vh" }, overflow: "auto" }}
            >
              <MyFileViewer text={text} />
            </Grid>
          </Grid>
        </AuthWrapperModal>
      </Modal>

      <Divider sx={{ p: 0, m: 0 }} />

      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: { xs: "space-between", sm: "space-around" },
            // alignItems: 'center',
            flexWrap: "wrap",
            width: "100%",
            height: "100%",
            // background: 'green'
          }}
        >
          <Typography
            variant="h3"
            component={RouterLink}
            to="/"
            underline="none"
            color="primary"
            fontSize={15}
            sx={{
              textDecoration: "none",
              width: { xs: "100%", sm: "fit-content" },
            }}
          >
            <img
              alt="KenyaFIT"
              loading="lazy"
              src={Logo}
              style={{
                maxHeight: 27,
                maxWidth: 100,
                borderRadius: 5,
                alignSelf: "center",
                justifySelf: "center",
                marginTop: 7,
              }}
            />
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 3,
            }}
          >
            <Box sx={{ diplay: "flex", flexDirection: "column" }}>
              <Typography variant="h4">Contact Us</Typography>

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {contactItems.map((it, idx) => (
                  <Typography
                    key={idx}
                    component={Link}
                    variant="caption"
                    href={it.href}
                    onClick={it.onClick}
                    sx={{ cursor: "pointer" }}
                  >
                    {it.name}
                  </Typography>
                ))}
              </Box>
            </Box>

            <Box sx={{ diplay: "flex", flexDirection: "column" }}>
              {/* <Typography variant="h4">Legal</Typography> */}

              {/* <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                {legalItems.map((it, idx) => (
                                    <Typography key={idx} component={Link} variant="caption" sx={{ cursor: "pointer" }} onClick={it.onClick}>
                                        {it.name}
                                    </Typography>
                                ))}
                            </Box> */}
            </Box>
          </Box>
        </Box>
      </Container>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          background: "#110c1a",
        }}
      >
        <Typography variant="subtitle1" sx={{ color: "white" }}>
          Copyright &copy; 2024-{new Date().getFullYear()} KenyaFIT
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
