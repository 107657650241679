import React from 'react';

import { useLocation } from 'react-router';

export function useDocumentTitle(title = '', prevailOnUnmount = false) {
    const defaultTitle = React.useRef(document.title);
    const location = useLocation();

    React.useEffect(() => {
        if (location === '/' && title === '') {
            document.title = 'Kenya FIT';
        } else {
            document.title = `Kenya FIT | ${title}`;
        }
    }, [title, location]);

    React.useEffect(
        () => () => {
            if (!prevailOnUnmount) {
                document.title = defaultTitle.current;
            }
        },
        [prevailOnUnmount]
    );
}
