import PropTypes from 'prop-types';

import { useDocumentTitle } from "../hooks/documentTitle";

const Title = ({ title }) => {
    useDocumentTitle(title);
    return <></>;
};

Title.propTypes = {
    title: PropTypes.string
};

export default Title;