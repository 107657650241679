import React from "react";
import ReactGA from "react-ga";

import Routes from "routes";

import ThemeCustomization from "themes";
import ScrollTop from "components/ScrollTop";

const GOOGLE_TAG = "G-6L6CDP8FYN";

ReactGA.initialize(GOOGLE_TAG);

const App = () => {
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ThemeCustomization>
      <ScrollTop>
        <Routes />
      </ScrollTop>
    </ThemeCustomization>
  );
};

export default App;