import React from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import {
    Checkbox,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    OutlinedInput,
    Stack,
    Typography
} from '@mui/material';

import * as Yup from 'yup';
import { Formik } from 'formik';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';

import { useCookies } from 'react-cookie';

import { getAccessToken } from './repository/login';
import AnimateButton from '../../@extended/AnimateButton';

const Auth = ({ handleLoadUser }) => {
    const [_, setCookie] = useCookies(['access']);
    const navigate = useNavigate();

    // const [checked, setChecked] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => event.preventDefault();

    const formInitialValue = {
        email: '',
        password: '',
        submit: null
    };

    const formValidationSchema = Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        password: Yup.string().min(8).max(255).required('Password is required')
    });

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const handleOnSubmit = (values, { setErrors, setStatus, setSubmitting }) => {
        setSubmitting(true);
        console.log("sub -> true");
        // await sleep(500);

        const data = {
            email: values.email,
            password: values.password
        };

        getAccessToken(data, (error, data) => {
            console.log(error, data);
            if (error) {
                setErrors({ ...error, submit: error['detail'] });
                setStatus({ success: false });
            } else {
                const access = data['access'];
                if (access) {
                    const date = new Date();
                    date.setDate(new Date().getDate() + 365);
                    setCookie('access', access, {
                        path: '/',
                        expires: date,
                        maxAge: 3600 * 24 * 364
                    });
                    setStatus({ success: true });
                    // navigate("/");
                    window.location.reload();
                    // handleLoadUser();
                } else {
                    setStatus({ success: false });
                    setErrors({ submit: 'Failed to login. Try again' });
                }
            }
            console.log("sub -> false");
            setSubmitting(false);
        });
    };

    return (
        <Formik initialValues={formInitialValue} validationSchema={formValidationSchema} onSubmit={handleOnSubmit}>
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit} autoComplete="off">
                    <Grid container spacing={3}>
                        <FormRow>
                            <InputLabel htmlFor="email-login">Email Address</InputLabel>
                            <OutlinedInput
                                id="email-login"
                                type="email"
                                value={values.email}
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter your email address"
                                fullWidth
                                autoComplete="off"
                                size="small"
                                error={Boolean(touched.email && errors.email)}
                            />
                            {touched.email && errors.email && (
                                <FormHelperText id="email-helper-text" error>
                                    {errors.email}
                                </FormHelperText>
                            )}
                        </FormRow>

                        <FormRow>
                            <InputLabel htmlFor="password-login">Password</InputLabel>
                            <OutlinedInput
                                fullWidth
                                error={Boolean(touched.password && errors.password)}
                                id="password-login"
                                value={values.password}
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                size="small"
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="off"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                placeholder="Enter password"
                            />
                            {touched.password && errors.password && (
                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                    {errors.password}
                                </FormHelperText>
                            )}
                        </FormRow>
                        <FormRow>
                            <Link variant="h6" component={RouterLink} to="/forgot-password" color="text.primary">
                                Forgot Password
                            </Link>
                        </FormRow>
                        {errors.submit && (
                            <Grid item xs={12}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <AnimateButton>
                                <LoadingButton
                                    loading={isSubmitting}
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="medium"
                                    // onClick={handleSubmit}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Login
                                </LoadingButton>
                            </AnimateButton>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    );
};

const FormRow = ({ children }) => (
    <Grid item xs={12}>
        <Stack spacing={1}>{children}</Stack>
    </Grid>
);

export default Auth;
