export default function ListItem(theme) {
    return {
        MuiListItem: {
            // styleOverrides: {
            //     root: {
            //         '.Mui-selected': {
            //             backgroundColor: 'red',
            //             '&:hover': {
            //                 backgroundColor: 'yellow',
            //             }
            //         },
            //     },
            // },
        }
    };
}
