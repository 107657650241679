import React from 'react';

import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const ScrollTop = ({ children }) => {
    const location = useLocation();
    const { pathname } = location;

    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [pathname]);

    return children || null;
};

ScrollTop.propTypes = {
    children: PropTypes.node
};

export default ScrollTop;
