import React from "react";

import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";

import AuthCard from "./AuthCard";

const AuthWrapperModal = ({ children }) => (
    <Box sx={{ minHeight: '100vh', overflow: 'auto' }}>
        <Grid
            container
            sx={{
                minHeight: '100vh',
                overflow: 'auto'
            }}
            spacing={0}
        >
            <Grid
                item
                xs={12}
                container
                spacing={0}
                justifyContent="center"
                alignItems="center"
                sx={{
                    minHeight: {
                        xs: 'calc(100vh - 134px)',
                        md: 'calc(100vh - 112px)'
                    },
                    overflow: 'auto'
                }}
            >
                {/* <Grid item> */}
                <AuthCard>{children}</AuthCard>
                {/* </Grid> */}
            </Grid>
        </Grid>
    </Box>
);

AuthWrapperModal.propTypes = {
    children: PropTypes.node
};

export default AuthWrapperModal;