import { Box, Button, Grid, IconButton, Stack, Typography, Divider } from '@mui/material';

import AuthRegister from '../form/AuthRegister';
import AuthWrapperModal from '../AuthWrapperModal';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PropTypes from 'prop-types';

const Register = ({ handleOpenLogin, handleClose }) => (
    <AuthWrapperModal>
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                    sx={{ mb: { xs: -0.5, sm: 0.5 } }}
                >
                    <Typography variant="h3">Register</Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography
                            component={Button}
                            onClick={handleOpenLogin}
                            variant="body1"
                            sx={{ textDecoration: 'none' }}
                            color="primary"
                        >
                            Already have an account?
                        </Typography>

                        <IconButton onClick={handleClose} aria-label="delete" color="primary">
                            <CloseRoundedIcon />
                        </IconButton>
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} sx={{ overflow: 'auto', maxHeight: '90vh' }}>
                <AuthRegister />
            </Grid>
        </Grid>
    </AuthWrapperModal>
);

Register.props = {
    handleClose: PropTypes.func,
    handleOpenLogin: PropTypes.func
};

export default Register;
