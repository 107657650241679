import React from 'react';

import {
    AppBar,
    Box,
    Slide,
    Toolbar,
    Typography,
    useScrollTrigger,
    InputBase,
    Divider,
    Paper} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import PropTypes from 'prop-types';
import ProfileNavButtons from './ProfileNavButtons';
import AuthButtons from './AuthButtons';

import { getUserInfo } from './repository';

import { DARK_PURPLE, SURFACE_WHITE, SURFACE_WHITE_2 } from '../../themes/colors';

import Logo from '../../assets/Logo.webp';
import { SearchBar } from './SearchBar';

const MAppBar = styled(AppBar)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1
}));

const HideOnScroll = (props) => {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined
    });

    return (
        <Slide sx={{ p: 0, m: 0 }} appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
};

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func
};


const TopAppBar = () => {
    const theme = useTheme();

    const [cookies] = useCookies(['access']);
    const [isLoggedIn, setIsLoggedIn] = React.useState(true);
    const [user, setUser] = React.useState({ });

    const location = useLocation();

    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        // console.log(location.pathname);
        const path = location.pathname;
        if (path.length >= 15 && path.slice(0, 15) === "/account/upload") {
            setVisible(false);
        } else {
            setVisible(true);
        }
    }, [location]);

    const handleLoadUser = () => {
        setIsLoggedIn(Boolean(cookies.access));
    };

    React.useState(() => {
        handleLoadUser();
    }, [cookies]);

    return (
        <MAppBar
            position="sticky"
            elevation={1}
            sx={{
                display: visible ? 'flex' : 'none',
                justifyContent: 'center'
            }}
            style={{
                background: SURFACE_WHITE,
                height: 50
            }}
        >
            <Toolbar>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexGrow: 1
                    }}
                >
                    <Typography
                        variant="h3"
                        component={RouterLink}
                        to="/"
                        underline="none"
                        color="primary"
                        fontSize={15}
                        sx={{ textDecoration: 'none' }}
                    >
                        <img
                            alt="logo"
                            loading="lazy"
                            src={Logo}
                            style={{
                                maxHeight: 27,
                                maxWidth: 100,
                                borderRadius: 5,
                                alignSelf: 'center',
                                justifySelf: 'center',
                                marginTop: 7
                            }}
                        />
                    </Typography>

                    {/* <SearchBar /> */}

                    {/* <Box sx={{ display: 'none', height: '100%', alignItems: 'center', gap: 2 }}>
                        <Typography
                            variant="subtitle1"
                            component={RouterLink}
                            to="/explore"
                            underline="none"
                            // color="primary"
                            fontSize={18}
                            sx={{
                                display: { xs: 'none', sm: 'flex' },
                                textDecoration: 'none',
                                color: theme.palette.text.primary
                            }}
                        >
                            Explore
                        </Typography>

                        {isLoggedIn ? (<ProfileNavButtons user={user} />) : <AuthButtons loadUser={handleLoadUser} />}
                    </Box> */}
                </Box>
            </Toolbar>
        </MAppBar>
    );
};

export default TopAppBar;
